import React from "react";
import { Link } from 'gatsby'

const CTABanner = ({ data }) => {
    const { title, button } = data
    return (
        <div className="flex justify-center items-center h-80 bg-gray-darkest">
            <div className="text-center">
                <div className="font-bold font-rubik text-3xl md:text-5xl text-white pb-4">{title}</div>
                <Link to="/contact"><button className="btn border-2 text-yellow hover:text-gray-dark">{button}</button></Link>
            </div>

        </div>
    );
}

export default CTABanner