import React from 'react'
import ServicesLeftCard from '../Services/ServicesLeftCard'
import ServicesRightCard from '../Services/ServicesRightCard'

const ServicesSection = ({ data }) => {
    const leftSection = data[1]
    const rightSection = data[0]

    return (
        <>
            <ServicesLeftCard data={leftSection} />
            <div className="py-6">
                <ServicesRightCard data={rightSection} />
            </div>
        </>
    )
}

export default ServicesSection