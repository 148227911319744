import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import Hero from '../components/Home/Hero'
import HomeBanner from '../components/Home/Banners/HomeBanner'
import ServicesSection from '../components/Home/Services/ServicesSection'
import SectionBanner from '../components/SectionBanner'
import About from '../components/Home/AboutSection/About'
import CTABanner from '../components/CTABanner'
import SEO from '../components/SEO'

export default ({ data }) => {
  const componentData = data.contentfulPage.components
  const heroData = componentData[6]
  const bannerOne = componentData[5]
  const servicesData = [componentData[3], componentData[4]]
  const homeSectionBannerData = componentData[2]
  const aboutSection = componentData[1]
  const callToActionBanner = componentData[0]
  const phoneNumber = data.contentfulCompanyInfo.phoneNumber
 const seoDescription = `We provide 24/7 emergency or scheduled home renovation and construction services. Serving Northamptonshire for over 20 years with our quality craftsmanship. Call us at ${phoneNumber} for an easy, friendly consultation to get started today.
 `




  return (
    <Layout>
      <SEO title="VM Construction | Construction & Home Renovation Experts" description={seoDescription} />
      <Hero data={heroData} />
      <HomeBanner data={bannerOne} />
      <ServicesSection data={servicesData} />
      <SectionBanner data={homeSectionBannerData} />
      <About data={aboutSection} />
      <CTABanner data={callToActionBanner} />
    </Layout>
  )
}

export const query = graphql`
query HomePage {
  contentfulPage(contentful_id: {eq: "77Mtim1qpDDuANmHETJgRj"}) {
    id
    title
    description
    components {
      ... on ContentfulHero {
        firstTitle
        secondTitle
        buttonText
        heroImages {
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
        childContentfulHeroParagraphTextNode {
          childMarkdownRemark {
            html
          }
        }
        childContentfulHeroSubtitleTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulBanner {
        title
        subtitle
        bannerImage {
          gatsbyImageData(quality: 90, width: 1200)
            }
      }
      ... on ContentfulContentModules {
        title
        listOfItems
        buttonLinkName
        componentImage {
          gatsbyImageData(quality: 90, width: 800)
        }
        childContentfulContentModulesParagraphTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulContactBanner {
        title
        button
      }
    }
  }
  contentfulCompanyInfo {
		phoneNumber
  }
}





`