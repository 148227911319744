import React from "react";
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


export const About = ({ data }) => {

    const { title, childContentfulContentModulesParagraphTextNode: { childMarkdownRemark: { html } }, componentImage, buttonLinkName } = data
    const image = getImage(componentImage)
    return (
        <>
            <div className="flex flex-wrap lg:flex-row">
                <div className="w-full md:w-1/2">
                    <GatsbyImage alt="VM Construction Team Photo" className="w-full h-full" image={image} />
                </div>
                <div data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease" className="flex py-4 flex-wrap lg:flex-col lg:justify-center items-center w-full md:w-1/2 md:pr-4 md:pl-10 px-10">
                    <h3 className="font-rubik font-bold text-gray-dark">{title}</h3>
                    <div className="font-roboto py-4 xl:max-w-xl" dangerouslySetInnerHTML={{ __html: html }}></div>
                    <div className="font-rubik font-medium underline hover:text-yellow py-4 text-gray-dark"><Link to="/about">{buttonLinkName}</Link></div>
                </div>

            </div>

        </>
    );
}

export default About