import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiCheckShield } from 'react-icons/bi';


const ServicesLeftCard = ({ data }) => {

    const { title, childContentfulContentModulesParagraphTextNode: { childMarkdownRemark: { html } }, listOfItems, componentImage } = data
    const image = getImage(componentImage)

    return (
        <>
            <div className="flex flex-wrap lg:flex-row px-10">
                <div className="w-full md:w-1/2 md:px-4">
                    <h3 className="font-rubik font-bold text-gray-dark md:pt-16">{title}</h3>
                    <div className="font-roboto py-4 xl:max-w-xl" dangerouslySetInnerHTML={{ __html: html }}></div>
                    <div>
                        {listOfItems && listOfItems.map((item, index) => {
                            return <div data-sal="slide-down"
                            data-sal-delay="800"
                            data-sal-easing="ease" key={index} className="flex items-center py-2"><BiCheckShield className="text-lg text-purple"></BiCheckShield><div className="text-md pl-2 font-medium text-gray-dark">{item}</div></div>
                        })}
                    </div>
                </div>

                <div data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease" className="w-full md:w-1/2 pt-4">
                    <GatsbyImage alt="VM Construction Construction & Renovation Service" className="w-full" image={image}/>
                </div>

            </div>
        </>
    )
}

export default ServicesLeftCard