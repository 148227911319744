import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ImPlus } from 'react-icons/im';




const Hero = ({ data }) => {
    const { firstTitle, secondTitle, buttonText, childContentfulHeroSubtitleTextNode: { childMarkdownRemark: { html: heroSubtitle } }, childContentfulHeroParagraphTextNode: { childMarkdownRemark: { html: heroParagraph } } } = data
    const image = getImage(data.heroImages[0])
    return (
        <div className="relative ">
            <GatsbyImage alt="Bright Kitchen Renovation" className="h-screen z-10" image={image} />
            <div className="absolute z-20 top-20 sm:top-40 md:top-16 2xl:top-40 md:left-20 md:p-10 p-4 pl-12 pr-12 xl:max-w-6xl">
                <div className="hero-text xl:min-w-full">
                    <h1 data-sal="slide-left"
                        data-sal-delay="500"
                        data-sal-easing="ease-out-back" className="pb-4">{firstTitle}</h1>
                    <ImPlus className="text-purple inline-block text-center md:text-left" />
                    <h2 data-sal="slide-left"
                        data-sal-delay="500"
                        data-sal-easing="ease-out-back" className="pt-4 pb-4">{secondTitle}</h2>
                </div>
                <div className="max-w-2xl rounded bg-gray-dark bg-opacity-30 px-2 pb-4">
                    <div className="text-roboto font-medium lg:text-2xl text-white pt-4" dangerouslySetInnerHTML={{ __html: `<div> ${heroSubtitle}</div> ` }}></div>
                    <div className="text-roboto lg:text-lg text-white py-2 md:py-4" dangerouslySetInnerHTML={{ __html: heroParagraph }}></div>
                    <Link className="" to="/contact"><button className="btn btn-gray hover:bg-gray-darkest">{buttonText}</button></Link>
                </div>
            </div>

        </div>
    )
}

export default Hero