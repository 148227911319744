import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BiCheckShield } from 'react-icons/bi';
import { Link } from "gatsby"



const ServicesRightCard = ({ data }) => {

    const { title, childContentfulContentModulesParagraphTextNode: { childMarkdownRemark: { html } }, listOfItems, componentImage, buttonLinkName } = data
    const image = getImage(componentImage)

    return (
        <>
            <div className="flex flex-wrap lg:flex-row px-10">
                <div data-sal="slide-up"
                    data-sal-delay="300"
                    data-sal-easing="ease" className="w-full md:w-1/2">
                    <GatsbyImage alt="VM Construction Construction & Renovation Service" className="w-full" image={image} />
                </div>
                <div className="w-full md:w-1/2 md:pr-4 md:pl-10">
                    <h3 className="font-rubik font-bold text-gray-dark pt-16">{title}</h3>
                    <div className="font-roboto py-4 xl:max-w-xl multiline-text" dangerouslySetInnerHTML={{ __html: html }}></div>
                    <div>
                        {listOfItems.map((item, index)=> {
                            return <div data-sal="slide-down"
                            data-sal-delay="800"
                            data-sal-easing="ease" key={index} className="flex items-center py-2"><BiCheckShield className="text-lg text-purple"></BiCheckShield><div className="text-md pl-2 font-medium text-gray-dark">{item}</div></div>
                        })}
                    </div>
                    <div className="text-right font-rubik font-medium underline hover:text-gray-lightest py-4 text-gray-dark"><Link to="/services">{buttonLinkName}</Link></div>
                </div>

            </div>
        </>
    )
}

export default ServicesRightCard