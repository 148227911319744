import React from 'react'


const HomeBanner = ({ data }) => {
   const { title, subtitle } = data
    return (
        <>
        <div   data-sal="slide-up"
  data-sal-delay="300"
  data-sal-easing="ease-out-back" className="flex flex-col text-center items-center text-gray-dark font-rubik py-16 px-2">
            <div className="font-bold text-2xl md:text-4xl">{title}</div>
            <div className="font-medium text-xl md:text-3xl">{subtitle}</div>
        </div>
        </>
    )
}

export default HomeBanner